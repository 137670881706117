export default {
    productList: [
        {
            id: 11,
            img: require("@a/images/kcc_img.png"),
            title: "快查查",
            dec: "快查查是一款全方位关注中老年人生活的app软件，给中老年人的生活中给予一定的便捷。软件中具即有健康码、实时公交、网上预约挂号这类便民生活服务，还有手电筒、放大镜以及各种热门软件的使用教程这类的便捷小工具。也能轻松教父母长辈使用手机，解决手机使用问题，关怀长辈的应用，还有更多方便使用的功能，方便长辈快速使用手机。",
            btnType: 2,//1 ios 2 android
            qrCodeShow: false,
            qrCodeUrl: require("@a/qrcode/kuaichacha.png")
        },
    ]
}